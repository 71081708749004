@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  font-style: initial;
  font-weight: 500;
  src: url("/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins";
  font-style: bolder;
  font-weight: 700;
  src: url("/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins";
  font-style: thin;
  font-weight: 300;
  src: url("/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins";
  font-style: light;
  font-weight: 200;
  src: url("/fonts/Poppins-ExtraLight.ttf");
}
@font-face {
  font-family: "Poppins";
  font-style: bold;
  font-weight: 600;
  src: url("/fonts/Poppins-SemiBold.ttf");
}

html {
  font-family: "Poppins";
  font-weight: 400;
}

.leaflet-icon {
  background-color: #cda57a;
  color: white;
  font-size: 8px;
  border: 0.5px #cda57a solid;
  border-radius: 80px;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.leaflet-img-icon {
  border-radius: 80px;
  width: 40px !important;
  height: 40px !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.tooltip {
  position: relative;
  display: inline-block;
}
/* 
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;

  width: 16px;
  height: 16px;

  display: grid;
  place-content: center;

  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
} */

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  /* clipPath: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); */
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.transition-left {
  transition-property: left;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-right {
  transition-property: right;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
